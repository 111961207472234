import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { navigate } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import {
  CircularProgress,
  Grid,
  Modal,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core'
import { CommonButton, Layout } from 'components'

import { updateToRefreshTrue } from 'actions/algoliaAction'
import { updateCurrentUser } from 'actions/userActions'
import { HOME_ENDPOINT } from 'constants/apiUrls'
import { BUTTON_STYLE, BUTTON_VARIANT } from 'constants/buttonConstants'
import {
  importThingsToDesigns,
  validateCode,
} from 'services/ThingiverseService'

const useStyles = makeStyles(theme => ({
  button: {
    textAlign: 'center',
    marginTop: '8px',
  },
  flexContainer: {
    display: 'flex',
  },
  modal: {
    display: 'flex',
    padding: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 600,
    backgroundColor: theme.palette.background.white,
    borderRadius: '0px 0px 4px 4px',
    padding: theme.spacing(2, 4, 3),
  },
  progress: {
    marginBottom: '8px',
    marginTop: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  stepper: {
    borderRadius: '4px 4px 0px 0px',
    '& .MuiStepIcon-text': {
      fill: theme.palette.text.white,
    },
  },
}))

const ThirdPartyOnboardingPage = ({ location }) => {
  const loggedUser = useSelector(state => state.userState.user)
  const { search = '' } = location
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(true)
  const steps = ['Linking account', 'Import designs']

  useEffect(() => {
    const linkWithThingiverse = async code => {
      setLoading(true)
      setOpen(true)
      try {
        await validateCode(loggedUser.uid, code)
        updateCurrentUser({ ...loggedUser, integrated_with_thingiverse: true })
      } catch (error) {
        console.log(error.message)
      }
      setLoading(false)
      setActiveStep(1)
    }

    const [, code] = search.split('&code=')
    if (loggedUser.isAnonymous || !code || code.length <= 0) {
      navigate(HOME_ENDPOINT)
    } else {
      linkWithThingiverse(code)
    }
  }, [loggedUser, search])

  const handleImportDesigns = async isPublic => {
    setLoading(true)
    setError('')
    try {
      await importThingsToDesigns(loggedUser.uid, isPublic)
      setOpen(false)
      updateToRefreshTrue()
      navigate(HOME_ENDPOINT)
    } catch (error) {
      setError(
        'There was a problem importing your designs, please try again later'
      )
    }
    setLoading(false)
  }

  const getStepContent = stepIndex => {
    if (stepIndex === 0) {
      return (
        <Grid className={classes.paper} container>
          <Grid item xs={12}>
            <Typography align="center" display="block" variant="h4">
              We are linking the account
            </Typography>
          </Grid>
          <Grid className={classes.flexContainer} item xs={12}>
            {loading && <CircularProgress className={classes.progress} />}
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" display="block" variant="h6">
              Please wait a moment...
            </Typography>
          </Grid>
        </Grid>
      )
    } else {
      return (
        <Grid className={classes.paper} container>
          <Grid item xs={12}>
            <Typography align="center" display="block" variant="h4">
              Import your designs
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography align="center" display="block" variant="h6">
              Would you like to import them as public or private?
            </Typography>
          </Grid>
          <Grid className={classes.button} item xs={12}>
            {loading && <CircularProgress className={classes.progress} />}
            {error && <div>{error}</div>}
          </Grid>
          <Grid className={classes.button} item xs={6}>
            <CommonButton
              buttonStyle={BUTTON_STYLE.CANCEL}
              disabled={loading}
              fullWidth={false}
              label="Public"
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={() => handleImportDesigns(true)}
            />
          </Grid>
          <Grid className={classes.button} item xs={6}>
            <CommonButton
              buttonStyle={BUTTON_STYLE.CANCEL}
              disabled={loading}
              fullWidth={false}
              label="Private"
              variant={BUTTON_VARIANT.OUTLINED}
              onClick={() => handleImportDesigns(false)}
            />
          </Grid>
        </Grid>
      )
    }
  }

  return (
    <Layout>
      <div>
        <Modal
          className={classes.modal}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          open={open}
        >
          <div className={classes.container}>
            <Stepper
              activeStep={activeStep}
              className={classes.stepper}
              alternativeLabel
            >
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel className={classes.step}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            <div>{getStepContent(activeStep)}</div>
          </div>
        </Modal>
      </div>
    </Layout>
  )
}

ThirdPartyOnboardingPage.propTypes = {
  location: PropTypes.object,
}

ThirdPartyOnboardingPage.defaultProps = {
  location: {},
}

export default ThirdPartyOnboardingPage
